<template>
  <CContainer class="d-flex align-items-center min-vh-100">
    <CRow class="w-100 justify-content-center">
      <CCol md="6">
        <div class="clearfix">
          <h1 class="float-left display-3 mr-4">403</h1>
          <h4 class="pt-3">Unauthorized!</h4>
          <p class="text-muted">You are not authorized to view this page.</p>
        </div>
        <div class="clearfix">
          <CButton color="info" class="mr-4" @click="onBack">Back</CButton>
          <CButton color="danger" class="mr-4" @click="onLogout" :disabled="is_loading">Logout</CButton>
        </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import { AUTH_LOGOUT } from "@/store/actions/auth";

export default {
  name: 'Unauthorized',
  data() {
    return {
      is_loading: false
    };
  },
  methods: {
    onBack: function() {
      this.$router.go(-2);
    },
    onLogout: function() {
      this.is_loading = true;
      this.$store.dispatch(AUTH_LOGOUT).then(() => {
        this.is_loading = false;
        this.$router.push('/login');
      }).catch(() => {
          this.is_loading = false;
      });
    }
  }
}
</script>
